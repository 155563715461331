import React from "react";
import ProjectInner from "./ProjectInner";

const Projects = () => {
  return (
    <div className="portfolio-content-inner" id="project">
      <h2 className="heading">Projects</h2>
      <div className="projects">
        <ProjectInner
          name="React Login/Signup"
          github="https://github.com/mafaqarif/react-login-page-demo"
          website="#"
        />

        <ProjectInner
          name="React Food order App"
          github="https://github.com/mafaqarif/react-food-app-demo"
        />

        <ProjectInner
          name="Angular Hotel inventory App"
          github="https://github.com/mafaqarif/hotel-inventory-app-Angular"
        />

        <ProjectInner
          name="LazeezNJ restruant"
          website="https://lazeeznj.com/"
        />
        {/* skill end */}
      </div>
    </div>
  );
};

export default Projects;
