import React from "react";

const Overview = () => {
  return (
    <div className="portfolio-content-inner" id="overview">
      <h2 className="heading">Who am I?</h2>
      <p className="content">
        Experienced software developer with over 4 years of experience in
        developing numerous end-to-end complex enterprise systems and software
        solutions for E-Commerce, Ed-Tech and HealthCare industries. Expertise
        in cutting-edge technologies such as Java, Spring Boot, RESTful Web
        Services, ReactJS, Angular, NextJS, NodeJS, JUnit, Docker, PostgreSQL,
        MongoDB and Amazon Web Services.
      </p>
    </div>
  );
};

export default Overview;
