import React from "react";

const ContactForm = () => {
  const [formState, setFormState] = React.useState({
    name: "",
    messageFrom: "",
    phone: "",
    messageBody: "",
  });

  const changeHandler = (e) => {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };

  const FormHandler = async (e) => {
    e.preventDefault();
    alert("Form submitted successfully!");
    console.log("state ", formState);

    try {
      const response = await fetch(
        // "https://jpckmfcuv2.execute-api.us-east-2.amazonaws.com/default/portfolioLambda",
        "https://6lndsrdcp65os6mdqgiqkvlgqm0dtzea.lambda-url.us-east-2.on.aws/",
        {
          method: "POST", // or 'POST', 'PUT', 'DELETE', etc.
          headers: {
            "Content-Type": "application/json",
            // Add any additional headers if necessary
          },
          body: JSON.stringify(formState),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      console.log("done");
      //   const result = await response.json();
      //   setData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <div className="portfolio-content-inner" id="contact">
      <h2 className="heading">Contact</h2>
      <ul>
        <li>
          <i className="fas fa-phone"></i>
          <span>Phone: +1 (641) 233-9764</span>
        </li>
        <li>
          <i className="fas fa-envelope"></i>
          <span>Email: afaqarif45@gmail.com</span>
        </li>
        <li>
          <span>
            Resume:{" "}
            <a href="https://docs.google.com/document/d/1Yfh2BS16WJ3XhiqLFfQ-8LoZz-DJqET0jYfYmYfjKZU/edit?usp=sharing">
              Download
            </a>
          </span>
        </li>
      </ul>

      <form className="contact-form" name="contact" onSubmit={FormHandler}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          required
          onChange={changeHandler}
        />
        <input
          type="email"
          name="messageFrom"
          placeholder="Email"
          required
          onChange={changeHandler}
        />
        <input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          required
          onChange={changeHandler}
        />
        <textarea
          name="messageBody"
          placeholder="Message"
          required
          onChange={changeHandler}
        ></textarea>
        <input type="submit" value={"Send Message"} />
      </form>

      <p>
        <small>
          *All fields are required. Please note that I will not share your
          contact information with anyone.
        </small>
      </p>
    </div>
  );
};

export default ContactForm;
