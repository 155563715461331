import React from "react";

const ProjectInner = (props) => {
  return (
    <div className="project">
      <h3>{props.name}</h3>
      <div className="project-links">
        {props.github && (
          <a href={props.github}>
            <img src="./icons/github.png" />
          </a>
        )}
        {props.website && (
          <a href={props.website}>
            <img src="./icons/link.png" />
          </a>
        )}
      </div>
    </div>
  );
};

export default ProjectInner;
