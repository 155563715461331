import React from "react";

const Skill = () => {
  return (
    <div className="portfolio-content-inner" id="skills">
      <h2 className="heading">Skills</h2>
      <div className="skills">
        <div className="skill">
          <img src="./icons/java.png" />
          <h3>Java</h3>
        </div>
        {/* skill end */}
        <div className="skill">
          <img src="./icons/spring-boot.png" />
          <h3>Spring Boot</h3>
        </div>

        <div className="skill">
          <img src="./icons/js.png" />
          <h3>JavaScript</h3>
        </div>
        <div className="skill">
          <img src="./icons/typescript.png" />
          <h3>TypeScript</h3>
        </div>
        <div className="skill">
          <img src="./icons/atom.png" />
          <h3>ReactJS</h3>
        </div>
        <div className="skill">
          <img src="./icons/python.png" />
          <h3>Python</h3>
        </div>
        <div className="skill">
          <img src="./icons/django.png" />
          <h3>Django</h3>
        </div>
        <div className="skill">
          <h3>And More</h3>
        </div>
      </div>
    </div>
  );
};

export default Skill;
