import logo from "./logo.svg";
import "./App.css";
import Sidebar from "./components/sidebar";
import Overview from "./components/Overview";
import Skill from "./components/Skill";
import Goals from "./components/Goal";
import Projects from "./components/Project";
import ContactForm from "./components/contactForm";

function App() {
  return (
    <div className="container-main">
      <Sidebar />
      <div className="portfolio-content">
        <Overview />
        <Skill />
        <Goals />
        <Projects />
        <ContactForm />
      </div>
    </div>
  );
}

export default App;
