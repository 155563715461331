import React from "react";

const Goals = () => {
  return (
    <div className="portfolio-content-inner" id="goals">
      <h2 className="heading">Goals</h2>
      <p className="content">
        As a developer, my future goals are centered around continuous growth
        and innovation in the tech industry. I aim to deepen my expertise in
        cloud computing, particularly in AWS, and to master the latest
        advancements in AI and machine learning. My goal is to build scalable,
        efficient, and secure applications that not only solve complex problems
        but also contribute to meaningful, real-world impact. I aspire to lead
        and collaborate on projects that push the boundaries of technology,
        while also mentoring and inspiring the next generation of developers.
        Ultimately, I am committed to lifelong learning and adapting to the
        ever-evolving landscape of technology, ensuring that I remain at the
        forefront of industry advancements.
      </p>
    </div>
  );
};

export default Goals;
