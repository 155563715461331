import React from "react";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar-container">
        <div class="image-section sections">
          <img className="sidebar-image" src="./images/profile-pic1.png" />
        </div>

        <div className="link-section sections">
          <a
            href="https://www.linkedin.com/in/afaq-arif45/"
            className="sidebar-link"
          >
            <img className="sidebar-icons" src="./icons/linkedin-logo.png" />
          </a>
          <a href="https://github.com/mafaqarif" className="sidebar-link">
            <img className="sidebar-icons" src="./icons/github.png" />
          </a>
          <a href="mailto:afaqarif45@gmail.com" className="sidebar-link">
            <img className="sidebar-icons" src="./icons/email.png" />
          </a>
        </div>

        <div className="portfolio-section sections">
          <a href="#overview">
            <p>Overview</p>
          </a>
          <a href="#skills">
            <p>Skill</p>
          </a>
          <a href="#goals">
            <p>Goals</p>
          </a>
          <a href="#project">
            <p>Project</p>
          </a>

          <a href="#contact">
            <p>Contact</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
